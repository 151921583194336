<template>
	<div class="content-wrap">
		<!--<h1>财务月度报表</h1>-->
		<div class="ctms-action ctms-action_right">
			<Select
				v-model="searchMonth"
				:transfer="true"
				placeholder="请选择月份"
				@on-change="onSwitch"
				style="width: 150px"
			>
				<Option
					v-for="(item, index) in [3, 6, 9, 12, 15, 18, 21]"
					:value="item"
					:key="index"
					>最近{{ item }}月</Option
				>
			</Select>
		</div>
		<v-chart
			v-if="sourceData.fields && sourceData.fields.length"
			:forceFit="true"
			:height="dataGridHeight"
			:data="createDataSet(sourceData.data, sourceData.fields)"
		>
			<v-tooltip />
			<v-axis />
			<v-legend />
			<v-stack-bar position="key*value" color="name" :adjust="adjust" />
		</v-chart>
	</div>
</template>

<script>
import api from "@/api/report/report"
import { mapState } from "vuex"

const { apiGetProjectMonthReport } = api
const DataSet = require("@antv/data-set")

export default {
	name: "MonthlyFinancialStatement",
	data() {
		return {
			searchProjectId: this.$route.params.projectId, // 查询条件： 项目id
			searchMonth: 3, // 查询条件：月份数量
			sourceData: {
				fields: []
			},
			adjust: [
				{
					type: "dodge",
					marginRatio: 1 / 32
				}
			]
		}
	},
	created() {
		this.onSwitch()
	},
	methods: {
		createDataSet(sourceData = [], fields = []) {
			const dv = new DataSet.View().source(sourceData)
			dv.transform({
				type: "fold",
				fields,
				key: "key",
				value: "value"
			})
			return dv.rows
		},
		onSwitch() {
			this.$asyncDo(async () => {
				this.sourceData = (await apiGetProjectMonthReport(
					this.searchProjectId,
					this.searchMonth
				)) || { fields: [] }
			})
		}
	},
	computed: {
		...mapState("project", ["infoHeight"]),
		...mapState({
			dataGridHeight(state) {
				return state.contentHeight - this.infoHeight - 82
			}
		})
	}
}
</script>
<style scoped>
.ctms-action_right {
	display: flex;
	justify-content: flex-end;
}
</style>
